import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import { status } from '@src/global/status';
import * as utils from '@src/utils/duckHelpers';

export const collateralTemplateTypes = {
    ...utils.requestTypes('COLLATERAL_TEMPLATE'),
    COLLATERAL_TEMPLATE_REQUEST: 'COLLATERAL_TEMPLATE_REQUEST',
};

export const collateralTemplateActions = {
    request: (params) => utils.action(collateralTemplateTypes.COLLATERAL_TEMPLATE_REQUEST, { payload: params }),
    success: (response) => utils.action(collateralTemplateTypes.COLLATERAL_TEMPLATE_SUCCESS, { payload: response }),
    failure: (error) => utils.action(collateralTemplateTypes.COLLATERAL_TEMPLATE_FAILURE, { error }),
    collateralTemplateSet: (obj) => utils.action(collateralTemplateTypes.COLLATERAL_TEMPLATE_SET, obj),
};

const initialState = fromJS({
    loaded: 'loading',
    collateralTemplate: {},
    error: null,
});

export default (state = initialState, action) => {
    switch (action.type) {
    case collateralTemplateTypes.COLLATERAL_TEMPLATE_GET:
        return state.merge({
            loaded: status.LOADING,
            error: null,
        });
    case collateralTemplateTypes.COLLATERAL_TEMPLATE_REQUEST:
        return state.merge({
            loaded: status.LOADING,
            error: null,
        });
    case collateralTemplateTypes.COLLATERAL_TEMPLATE_SET:
        return state.merge({
            loaded: status.COMPLETE,
            collateralTemplate: action.payload.collateral_template || {},
        });
    case collateralTemplateTypes.COLLATERAL_TEMPLATE_FAILURE:
        return state.merge({
            loaded: status.FAILED,
            collateralTemplate: {},
            error: action.error,
        });
    case collateralTemplateTypes.COLLATERAL_TEMPLATE_RESET:
        return initialState;
    default:
        return state;
    }
};

const getCollateralTemplate = (state) => state.getIn(['app', 'collateralTemplate']);

export const selectors = {
    getCollateralTemplate: createSelector([getCollateralTemplate], (collateralTemplate) => collateralTemplate),
};
