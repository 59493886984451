import castArray from 'lodash/castArray';
import isArray from 'lodash/isArray';
import QS from 'qs';

const ADMIN_BASE_URL = process.env.ADMIN_HOST_URL;

const parseSearch = (search, opts = {}) => (
    QS.parse(
        search || '',
        { ignoreQueryPrefix: true, ...opts },
    )
);

const stringifySearch = (params, opts = {}) => (
    QS.stringify(
        params,
        { encodeValuesOnly: true, arrayFormat: 'brackets', ...opts },
    )
);

const adminResourceURL = (resourceType, id) => {
    switch (resourceType) {
    case 'ProductUpdate':
        return `${ADMIN_BASE_URL}/product_updates/${id}`;
    case 'Training':
        return `${ADMIN_BASE_URL}/trainings/${id}`;
    case 'JobAid':
        return `${ADMIN_BASE_URL}/job_aids/${id}`;
    case 'Webinar':
        return `${ADMIN_BASE_URL}/webinars/${id}`;
    case 'Alert':
        return `${ADMIN_BASE_URL}/alerts/${id}`;
    case 'UnderwritingGuideline':
        return `${ADMIN_BASE_URL}/underwriting_guidelines/${id}`;
    case 'RateAndRuleManual':
        return `${ADMIN_BASE_URL}/rate_bulletins/${id}`;
    case 'RateBulletin':
        return `${ADMIN_BASE_URL}/rate_bulletins/${id}`;
    case 'HomeLink':
        return `${ADMIN_BASE_URL}/home_links/${id}`;
    case 'CollateralTemplate':
        return `${ADMIN_BASE_URL}/collateral_templates/${id}`;
    default:
        return ADMIN_BASE_URL;
    }
};

const resourceURL = (pathname, slug) => {
    const urlPrefix = pathname.charAt(pathname.length - 1) === '/' ? pathname.slice(0, -1) : pathname;

    return `${urlPrefix}/${slug}`;
};

const resourceURLByType = (item) => {
    if (item.record_type === 'Collateral' || item.record_type === 'Article') {
        return '/marketing-collateral';
    }

    if (item.record_type === 'HomeLink') {
        return item.external_url;
    }

    return `${urlPrefixByType(item.record_type)}/${item.slug}`;
};

const urlPrefixByType = (type) => {
    switch (type) {
    case 'UnderwritingGuideline':
        return '/guides-and-rate-bulletins';
    case 'RateBulletin':
        return '/guides-and-rate-bulletins';
    case 'JobAid':
        return '/training-and-job-aids/job-aids';
    case 'Training':
        return '/training-and-job-aids/elearnings';
    case 'Webinar':
        return '/training-and-job-aids/webinars';
    case 'ProductUpdate':
        return '/products/product-news-and-updates';
    case 'FieldManagamentNews':
        return '/field-management/field-management-news';
    case 'Alert':
        return '/alerts';
    case 'Collateral':
        return '/sales-and-marketing';
    default:
        return '';
    }
};

const addParamsToSearch = (search, params = {}) => {
    const searchParams = parseSearch(search);
    return stringifySearch({ ...searchParams, ...params });
};

const removeParamFromSearch = (search, param) => {
    const searchParams = parseSearch(search);
    delete searchParams[param];
    return stringifySearch(searchParams);
};

const getPathnameLastPart = (pathname = '') => {
    pathname = pathname.replace(/\/$/, '');

    return pathname.substr(pathname.lastIndexOf('/') + 1);
};

const replaceMet360 = (productSlug) => {
    if (!productSlug) return;

    return productSlug.replace(/met360/, 'agent360');
};

const replaceMet360SearchParam = (search, paramNames) => {
    const params = parseSearch(search);

    paramNames = castArray(paramNames);
    paramNames.forEach((paramName) => {
        if (!params[paramName]) return;

        if (isArray(params[paramName])) {
            params[paramName] = params[paramName].map(replaceMet360);
        } else {
            params[paramName] = replaceMet360(params[paramName]);
        }
    });

    return stringifySearch(params);
};

export {
    parseSearch,
    stringifySearch,
    adminResourceURL,
    resourceURL,
    resourceURLByType,
    addParamsToSearch,
    removeParamFromSearch,
    getPathnameLastPart,
    replaceMet360SearchParam,
};
