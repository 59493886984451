import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('TAGS'),
    TAGS_SET: 'TAGS_SET',
    TAGS_GET: 'TAGS_GET',
    TAGS_SUCCESS: 'TAGS_SUCCESS',
};

export const actions = {
    tagsSet: (obj) => utils.action(types.TAGS_SET, obj),
};

const initialState = fromJS({
    loaded: 'loading',
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.TAGS_SET:
        if (action.payload.tags) {
            return fromJS({
                tags: action.payload.tags,
                loaded: 'complete',
            });
        }

        return fromJS({
            tags: [],
            loaded: 'empty',
        });
    case types.TAGS_RESET:
        return initialState;

    default:
        return state;
    }
};

const getTags = (state) => state.get('app').get('tags');

export const selectors = {
    getTags: createSelector([getTags], (tags) => tags),
};
