import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { collateralRequestActions, collateralRequestTypes } from '@src/ducks/collateralRequest';
import { apiPatch, buildApiURL } from '@src/services/api';
import { handleSagaFailure } from '@src/utils/sagaHelpers';

export function * watchCollateralRequest() {
    yield takeLatest(collateralRequestTypes.COLLATERAL_REQUEST_GET, onGet);
    yield takeLatest(collateralRequestTypes.COLLATERAL_REQUEST_SUCCESS, onGetSuccess);
    yield takeEvery(collateralRequestTypes.COLLATERAL_REQUEST_UPDATE, onUpdate);
}

function * onGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onGetSuccess({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'collateral_request') {
        return yield all([
            put({
                type: collateralRequestTypes.COLLATERAL_REQUEST_SET,
                payload: response,
            }),
        ]);
    }
}

function * onUpdate({ payload }) {
    try {
        const response = yield call(
            apiPatch,
            buildApiURL(`/api/v1/collateral_requests/${payload.id}?uuid=${payload.uuid}`),
            payload,
        );

        yield put(collateralRequestActions.updateSuccess(response));
    } catch (error) {
        yield call(handleSagaFailure, error);
        yield put(collateralRequestActions.updateFailure(error));
    }
}
