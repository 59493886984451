import SetRoute from '../services/setRoute';

export const fetchTags = (fetch) => (
    {
        payload: {
            dataset: 'tags',
            action: 'get',
            route: SetRoute + '/api/v1/tags',
            data: {},
        },
        fetch,
    }
);

export default fetchTags;
