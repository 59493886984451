import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { cartItemsActions, cartItemsTypes } from '@src/ducks/cartItems';
import { apiDelete, apiGet, apiPost, buildApiURL } from '@src/services/api';
import { handleSagaFailure } from '@src/utils/sagaHelpers';

export function * watchCartItems() {
    yield takeLatest(cartItemsTypes.CART_ITEMS_GET, onCartItemsGet);
    yield takeLatest(cartItemsTypes.CART_ITEMS_REQUEST, onRequest);
    yield takeLatest(cartItemsTypes.CART_ITEMS_SUCCESS, onCartItemsGetResponse);
    yield takeEvery(cartItemsTypes.CART_ITEMS_CREATE, onCreate);
    yield takeEvery(cartItemsTypes.CART_ITEMS_REMOVE, onRemove);
}

function * onCartItemsGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onRequest({ payload }) {
    try {
        const url = buildApiURL('/api/v1/cart_items', payload);
        const response = yield call(apiGet, url);

        yield put(cartItemsActions.success(response));
    } catch (error) {
        yield call(handleSagaFailure, error);
        yield put(cartItemsActions.failure(error));
    }
}

function * onCartItemsGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'cart_items') {
        return yield all([
            put({
                type: cartItemsTypes.CART_ITEMS_SET,
                payload: response,
            }),
        ]);
    }
}

function * onCreate({ payload }) {
    try {
        const response = yield call(apiPost, buildApiURL('/api/v1/cart_items'), payload);

        yield put(cartItemsActions.createSuccess(response));
        yield put(cartItemsActions.request());
    } catch (error) {
        yield call(handleSagaFailure, error);
        yield put(cartItemsActions.createFailure(error));
    }
}

function * onRemove({ payload }) {
    try {
        const response = yield call(apiDelete, buildApiURL('/api/v1/cart_items/' + payload.id));

        yield put(cartItemsActions.removeSuccess(response));
        yield put(cartItemsActions.request());
    } catch (error) {
        yield call(handleSagaFailure, error);
        yield put(cartItemsActions.removeFailure(error));
    }
}
