import SetRoute from '../services/setRoute';

export const fetchCartItems = (
    fetch,
    param = '',
) => (
    {
        payload: {
            dataset: 'cart_items',
            action: 'get',
            route: SetRoute + `/api/v1/cart_items${param}`,
            data: {},
        },
        fetch,
    }
);

export default fetchCartItems;
