import { all, put, takeLatest } from 'redux-saga/effects';

import { types as tagsTypes } from '../ducks/tags';

export function * watchTags() {
    yield takeLatest(tagsTypes.TAGS_GET, onTagsGet);
    yield takeLatest(tagsTypes.TAGS_SUCCESS, onTagsGetResponse);
}

export function * onTagsGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

export function * onTagsGetResponse({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'tags') {
        return yield all([
            put({
                type: tagsTypes.TAGS_SET,
                payload: response,
            }),
        ]);
    }
}
