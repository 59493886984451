import { all, call, put, takeLatest } from 'redux-saga/effects';

import { collateralTemplateActions, collateralTemplateTypes } from '@src/ducks/collateralTemplate';
import { apiGet, buildApiURL } from '@src/services/api';
import { handleSagaFailure } from '@src/utils/sagaHelpers';

export function * watchCollateralTemplate() {
    yield takeLatest(collateralTemplateTypes.COLLATERAL_TEMPLATE_GET, onGet);
    yield takeLatest(collateralTemplateTypes.COLLATERAL_TEMPLATE_SUCCESS, onGetSuccess);
    yield takeLatest(collateralTemplateTypes.COLLATERAL_TEMPLATE_REQUEST, onRequest);
}

function * onGet({ payload }) {
    payload.method = 'get';
    return yield payload;
}

function * onGetSuccess({ response, payload }) {
    if (payload.action === 'get' && response && payload.dataset === 'collateral_template') {
        return yield all([
            put({
                type: collateralTemplateTypes.COLLATERAL_TEMPLATE_SET,
                payload: response,
            }),
        ]);
    }
}

function * onRequest({ payload }) {
    try {
        const url = buildApiURL(`/api/v1/collateral_templates/${payload.id}`);
        const response = yield call(apiGet, url);

        yield put(collateralTemplateActions.success(response));
    } catch (error) {
        yield call(handleSagaFailure, error);
        yield put(collateralTemplateActions.failure(error));
    }
}
