import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import * as utils from '../utils/duckHelpers';

export const types = {
    ...utils.requestTypes('STATES'),
    STATES_SET: 'STATES_SET',
    STATES_GET: 'STATES_GET',
    STATES_SUCCESS: 'STATES_SUCCESS',
};

export const actions = {
    statesSet: (obj) => utils.action(types.STATES_SET, obj),
};

const initialState = fromJS({
    loaded: 'loading',
});

export default (state = initialState, action) => {
    switch (action.type) {
    case types.STATES_SET:
        if (action.payload.states) {
            return fromJS({
                states: action.payload.states,
                loaded: 'complete',
            });
        }

        return fromJS({
            states: [],
            loaded: 'empty',
        });
    case types.STATES_RESET:
        return initialState;

    default:
        return state;
    }
};

const getStates = (state) => state.get('app').get('states');

export const selectors = {
    getStates: createSelector([getStates], (states) => states),
};
