import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import { status } from '@src/global/status';
import * as utils from '@src/utils/duckHelpers';

const cartItemsTypes = {
    ...utils.requestTypes('CART_ITEMS'),
    CART_ITEMS_CREATE: 'CART_ITEMS_CREATE',
    CART_ITEMS_CREATE_SUCCESS: 'CART_ITEMS_CREATE_SUCCESS',
    CART_ITEMS_CREATE_FAILURE: 'CART_ITEMS_CREATE_FAILURE',
    CART_ITEMS_REMOVE: 'CART_ITEMS_REMOVE',
    CART_ITEMS_REMOVE_SUCCESS: 'CART_ITEMS_REMOVE_SUCCESS',
    CART_ITEMS_REMOVE_FAILURE: 'CART_ITEMS_REMOVE_FAILURE',
    CART_ITEMS_CHECKOUT: 'CART_ITEMS_CHECKOUT',
};

const cartItemsActions = {
    request: (params) => utils.action(cartItemsTypes.CART_ITEMS_REQUEST, { payload: params }),
    success: (response) => utils.action(cartItemsTypes.CART_ITEMS_SUCCESS, { payload: response }),
    failure: (error) => utils.action(cartItemsTypes.CART_ITEMS_FAILURE, { error }),
    cartItemsSet: (obj) => utils.action(cartItemsTypes.CART_ITEMS_SET, obj),
    create: (data) => utils.action(cartItemsTypes.CART_ITEMS_CREATE, { payload: data }),
    createSuccess: (response) => utils.action(cartItemsTypes.CART_ITEMS_CREATE_SUCCESS, { payload: response }),
    createFailure: (error) => utils.action(cartItemsTypes.CART_ITEMS_CREATE_FAILURE, { error }),
    remove: (id) => utils.action(cartItemsTypes.CART_ITEMS_REMOVE, { payload: { id } }),
    removeSuccess: (response) => utils.action(cartItemsTypes.CART_ITEMS_REMOVE_SUCCESS, { payload: response }),
    removeFailure: (error) => utils.action(cartItemsTypes.CART_ITEMS_REMOVE_FAILURE, { error }),
    checkout: (params) => utils.action(cartItemsTypes.CART_ITEMS_CHECKOUT, { payload: params }),
};

const initialState = fromJS({
    loaded: status.LOADING,
    cartItems: [],
    page: 0,
    total: 0,
    error: null,
});

const cartItems = (state = initialState, action) => {
    switch (action.type) {
    case cartItemsTypes.CART_ITEMS_REQUEST:
        return state.merge({
            loaded: status.LOADING,
            error: null,
        });
    case cartItemsTypes.CART_ITEMS_SUCCESS:
        return state.merge({
            loaded: status.COMPLETE,
            cartItems: action.payload.cart_items,
            page: action.payload.page,
            total: action.payload.total,
            error: null,
        });
    case cartItemsTypes.CART_ITEMS_SET:
        if (action.payload.cart_items) {
            return fromJS({
                cartItems: action.payload.cart_items,
                total: action.payload.total,
                loaded: 'complete',
            });
        }

        return fromJS({
            cartItems: [],
            loaded: 'empty',
        });
    case cartItemsTypes.CART_ITEMS_FAILURE:
        return state.merge({
            loaded: status.FAILED,
            cartItems: [],
            page: 0,
            total: 0,
            error: action.error,
        });
    default:
        return state;
    }
};

const getCartItems = (state) => state.get('app').get('cartItems');

const selectors = {
    getCartItems: createSelector([getCartItems], (cartItems) => cartItems),
};

export {
    cartItems as default,
    cartItemsActions,
    cartItemsTypes,
    selectors,
};
