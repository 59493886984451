import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import { status } from '@src/global/status';
import * as utils from '@src/utils/duckHelpers';

export const collateralRequestTypes = {
    ...utils.requestTypes('COLLATERAL_REQUEST'),
    COLLATERAL_REQUEST_UPDATE: 'COLLATERAL_REQUEST_UPDATE',
    COLLATERAL_REQUEST_UPDATE_SUCCESS: 'COLLATERAL_REQUEST_UPDATE_SUCCESS',
    COLLATERAL_REQUEST_UPDATE_FAILURE: 'COLLATERAL_REQUEST_UPDATE_FAILURE',
};

export const collateralRequestActions = {
    collateralRequestSet: (obj) => utils.action(collateralRequestTypes.COLLATERAL_REQUEST_SET, obj),
    updateSuccess: (response) => utils.action(
        collateralRequestTypes.COLLATERAL_REQUEST_UPDATE_SUCCESS, { payload: response },
    ),
    updateFailure: (error) => utils.action(
        collateralRequestTypes.COLLATERAL_REQUEST_UPDATE_FAILURE, { error },
    ),
};

const initialState = fromJS({
    loaded: 'loading',
    collateralRequest: {},
    error: null,
});

export default (state = initialState, action) => {
    switch (action.type) {
    case collateralRequestTypes.COLLATERAL_REQUEST_GET:
        return state.merge({
            loaded: status.LOADING,
            error: null,
        });
    case collateralRequestTypes.COLLATERAL_REQUEST_SET:
        return state.merge({
            loaded: status.COMPLETE,
            collateralRequest: action.payload.collateral_request || {},
        });
    case collateralRequestTypes.COLLATERAL_REQUEST_FAILURE:
        return state.merge({
            loaded: status.FAILED,
            collateralRequest: {},
            error: action.error,
        });
    case collateralRequestTypes.COLLATERAL_REQUEST_RESET:
        return initialState;
    default:
        return state;
    }
};

const getCollateralRequest = (state) => state.getIn(['app', 'collateralRequest']);

export const selectors = {
    getCollateralRequest: createSelector([getCollateralRequest], (collateralRequest) => collateralRequest),
};
